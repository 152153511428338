import Pages from "./pages";
import Top from "./top";
import Bottom from "./bottom";
import Header from "./header";
import { Wrap } from "../core/ui";
import { useLocation } from "react-router-dom";
import { useRef, useState, useEffect, Dispatch, SetStateAction } from "react";

type dynamicProps = {
  setCakeDatePicked: Dispatch<SetStateAction<string>>;
}

export default function Content({
  setCakeDatePicked,
}: dynamicProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const path = location.pathname;

  const [scrollTop, setScrollTop] = useState(0);

  function doScroll(e: React.UIEvent<HTMLDivElement>) {
    setScrollTop(e.currentTarget.scrollTop);
  }

  return (
    <Wrap
      onScroll={doScroll}
      ref={scrollRef}
      style={{
        height: "100%",
        background: "#ffffff",
        overflow: path === "/order" ? "hidden" : "auto",
        overflowX: "hidden",
      }}
    >
      <Header scrollTop={scrollTop} />
      <Top />
      <Pages setCakeDatePicked={setCakeDatePicked} />
      <Bottom innerRef={bottomRef} />
    </Wrap>
  );
}

