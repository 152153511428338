import { BrowserRouter } from "react-router-dom";
import Dynamic from "./components/dynamic";
import Core from "./components/core";
import { useEffect, useState } from "react";

function App() {
  const [cakeDatePicked, setCakeDatePicked] = useState<string>('');

  useEffect(() =>{
    console.log("cakeDatePicked", cakeDatePicked);
  }, [cakeDatePicked]);

  return (
    <BrowserRouter>
      <Core cakeDatePicked={cakeDatePicked} />
      <Dynamic setCakeDatePicked={setCakeDatePicked} />
    </BrowserRouter>
  );
}

export default App;
