import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Col, Modal, ModalBody } from "./ui";
import { tryUpdateDatabase} from "../..";

type coreType = {
  cakeDatePicked: string;
}

export default function Core({
  cakeDatePicked
}: coreType) {
  return (
    <Routes>
      <Route
        path="success"
        element={<PaymentModal success dateToAssign={cakeDatePicked} />}
      />
      <Route path="fail" element={<PaymentModal />} />
    </Routes>
  );
}

function PaymentModal(
  props: {
    success?: boolean,
    dateToAssign?: string;
  },
) {
  useEffect(() => {
    // prevents multiple DB calls by staggering
    const timer = setTimeout(() => {
      if (props.success && props.dateToAssign) {
        tryUpdateDatabase(props.dateToAssign);
      }
    }, 10);

    return () => clearTimeout(timer);
  }, [props.success, props.dateToAssign]);

  const [show, setShow] = useState(true);
  let body = <div>Payment was cancelled.</div>;
  if (props.success) {
    body = (
      <Col style={{ alignItems: "center" }}>
        <div style={{ marginBottom: 20, fontWeight: 500 }}>
          Thank you for your purchase!!
        </div>
        <div style={{ fontWeight: 300 }}>
          Order details and receipt have been sent to your email.
        </div>
      </Col>
    );

  }

  return (
    <Modal open={show} onClose={() => setShow(false)}>
      <ModalBody>{body}</ModalBody>
    </Modal>
  );
}
