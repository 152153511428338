import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import About from "./about";
import Order from "./order";
import Shop from "./shop";
import Press from "./press";
import Return from "./return";
import { Wrap } from "../../core/ui";
import Contact from "./contact";

type dynamicProps = {
  setCakeDatePicked: Dispatch<SetStateAction<string>>;
};

export default function Dynamic({
  setCakeDatePicked,
}: dynamicProps ) {
  const page1 = useRef();
  const page2 = useRef();
  const page3 = useRef();
  const page4 = useRef();

  const refsByRoute: any = {
    "/about": page1,
    "/press": page2,
    "/newsletter": page3,
    "/return": page4,
  };

  const location = useLocation();

  useEffect(() => {
    const thisRef = refsByRoute[location.pathname];
    if (thisRef) {
      thisRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <Wrap>
      <About innerRef={page1} />
      <Press innerRef={page2} />
      <Return innerRef={page4} />

      <Order setCakeDatePicked={setCakeDatePicked} />
      <Shop />

      {/* <Contact /> */}
    </Wrap>
  );
}
