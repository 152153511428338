import styled from "styled-components";
import { allergenPolicyText, cakePolicyText, returnPolicyText } from "./constants";
import { PageWrap, Row, Col } from "../../../core/ui";
import { useIsMobile } from "../../../../hooks";

export default function Return({ innerRef }: any){
  const isMobile = useIsMobile();
  return(
    <PageWrap
      ref={innerRef}
      style={{ textAlign: "center", alignItems: "center" }}
    >
      <Title>Cake Policy</Title>
      <Row style={{ width: "100%" }}>
      <Col style={{ padding: 30, maxWidth: 500 }}>
          <Txt>{cakePolicyText}</Txt>
          </Col>
        </Row>
      <Title>Return Policy</Title>
      <Row style={{ width: "100%" }}>
      <Col style={{ padding: 30, maxWidth: 500 }}>
          <Txt>{returnPolicyText}</Txt>
          </Col>
        </Row>
        <Title> Allergen Warning </Title>
      <Row style={{ width: "100%" }}>
      <Col style={{ padding: 30, maxWidth: 500 }}>
          <Txt>{allergenPolicyText}</Txt>
          </Col>
        </Row>
          </PageWrap>)}

export const Title = styled.div`
font-size: 36px;
margin: 55px 0;
`;

export const Txt = styled.div`
display: flex;
font-size: 17px;
margin-bottom: 10px;
line-height: 24px;
`;