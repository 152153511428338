import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./dynamic.css";
import "react-datepicker/dist/react-datepicker.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, where, increment, updateDoc, addDoc } from 'firebase/firestore';
import { CakeLimitChecker } from "./components/core/form/inputs";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAARMRv8FcyXyB1EUdyDTsoeI0wo7t5eB8",
  authDomain: "lara-repository.firebaseapp.com",
  projectId: "lara-repository",
  storageBucket: "lara-repository.appspot.com",
  messagingSenderId: "477720642176",
  appId: "1:477720642176:web:017e31864757fa07bc9c65",
};


// Initialize Firebase
initializeApp(firebaseConfig);

// init service
const db = getFirestore();

// collection reference
export const cakeDatabase = collection(db, 'cakes');

export const cakeLimitArray: CakeLimitChecker[] = [];
// get collection data
getDocs(cakeDatabase)
  .then((querySnapshot) => {
    querySnapshot.docs.forEach((doc) => {
      if (doc.data().date !== '') {
        cakeLimitArray.push(new CakeLimitChecker(doc.data().date, doc.data().orders));
      }
    });
  }).catch((err) => {
  console.log('error fetching cakes', err);
});

export const tryUpdateDatabase = async (dateToAssign: string) => {
  const q = query(cakeDatabase, where("date", "==", dateToAssign));
  const querySnapshot = await getDocs(q);

  console.log('THIS IS A TEST JONA');
  let foundDate = false;

  for (const doc of querySnapshot.docs) {
    if (doc.data().date === dateToAssign) {
      foundDate = true;
      const docRef = doc.ref;

      await updateDoc(docRef, {
        orders: increment(1)
      });
      break;
    }
  }

  if (!foundDate) {
    await addToDatabase(dateToAssign);
  }
};

export const addToDatabase = async (dateToAssign: string) => {
  await addDoc(cakeDatabase, {
    date: dateToAssign.toString(),
    orders: 1
  });
}
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();