export const press = [
  {
    title: `NPR x KUOW Radio Feature`,
    link: `https://www.kuow.org/stories/this-seattle-bakery-sells-vegan-treats-and-funds-mutual-aid-efforts`,
    img: "kuow.jpg",
    text: `KUOW's own Ruby de Luna followed us around for a behind-the-scenes look at what it takes to run a bakery. Click here to listen to the full segment. Aired live on KUOW Seattle. `,
    sub: `“CHARITY IS OFTEN GIVEN FROM A MORE PRIVILEGED GROUP TO A LESS PRIVILEGED GROUP. WHEREAS MUTUAL AID IS GIVEN BY PEOPLE THAT ARE OFTEN PART OF THE SAME COMMUNITY THAT THEY ARE HELPING." `,
  },
  {
    title: `Interview with Eater Seattle Magazine`,
    img: "laraAndBakery.jpg",
    text: `Eater Seattle is a one of the largest food magazines in the US and is based in over a dozen cities. Click here to see the full write up on their website.  `,
    sub: `"IT'S A MORE TEDIOUS WAY TO ORGANIZE YOUR ORGANIZATION. AND IT'S MORE TIME-CONSUMING, MORE EMOTIONAL EFFORT. BUT IT'S IN SERVICE OF A GREATER GOOD, A DIFFERENT KIND OF GOAL. IT'S LESS TRANSACTIONAL." `,
  },
];