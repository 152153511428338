export const returnPolicyText = `

Custom cakes are held for one day unless contacted. After one day, the cake will be resold. If a cake is not picked up within one day it will be considered a canceled order with no refund. 
A cake cannot be moved unless contacted more than 48hrs in advance. Cakes cannot be refunded or canceled once they are baked. Cakes are baked the day before the order date selected. 
Cakes cannot be refunded for subjective reasons, such as disliking the design or cake color. Cakes are made according to the design selected by you. If you don’t like the design you selected this does not warrant a refund. Colors are matched approximately to the color selected, within a shade or two. The color develops over time and a perfect match is not guaranteed. We don’t offer refunds for cakes that are a slight shade different than selected. The only exception to this is if the color is completely different, for example, if you order a light pink cake and you receive a dark green one. If the color is completely different we can offer a correction or refund.
`;

export const allergenPolicyText = `
 
We are a vegan bakery, for moral reasons pertaining to not exploiting or hurting animals. We are not a bakery catering to dietary restrictions or allergies. We cannot guarantee that are items are free of nuts, soy, wheat or gluten. 
We do not have any animals products in the building and we can guarantee that our products don’t contain any animal products in the listed ingredients, which is what is in our control. Although, we can’t control the process that takes place prior to when the ingredients arrive at our door. Several of our products come from 100% vegan distributors and processors, but others come from distributors that have vegan products, but also have non-vegan products. So there is a chance that cross-contamination with dairy or egg products occur during transport on the same truck or during processing on the same machines. For this reason, we have to say that our items MAY CONTAIN milk and eggs. 
For those with severe allergies, we recommend only going to restaurants that have an allergy certification, which have rigorously audited by a third-party company such as KitchenswithConfidence or ServSafe, that certify a kitchen allergen-free all the way from production to service. We do not have such a certification nor do we plan to get one, and we have never claimed to have one. Please take this into consideration before dining out. 
`;

export const cakePolicyText = `
Cake pickup hours are 1-4pm on the date you selected. Cake orders must be placed at least 1 week in advance. If dates are blacked out, the max number of cakes have already been ordered for that day. Please select another day. \ 
The pink color on the example cake pictured is for illustration purposes, your cake may not be pink. No-shows will be held for one day unless we are contacted. Pickup instructions will also be sent out in a confirmation email.
`;


