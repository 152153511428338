export const text1 = `
Lazy Cow Bakery is a plant-based bakery located in Seattle's Fremont
            neighborhood. 
            `;

export const text2 = `
Lazy Cow Bakery has an associated social justice organization, La Casa Del Xoloitzcuintle. Casa del Xolo, for short. Located inside our bakery is our free community fridge &
food pantry. Donations are welcome anytime during normal business hours. Casa del Xolo holds private events and a weekly community dinner.
            `;

export const text3 = `
We hope to become an essential part of the Fremont neighborhood.
With your help we can build our reputation as a vegan bakery with a
standout business model based on compassion and community.
                        `;

export const hours: any = {
  label1: "Hours",
  text1: "Wednesday through Saturday 7:30am-5pm",
  text2: "Sunday 7:30-4pm",
  label2: "Address",
  text3: "3418 Fremont Ave N",
  text4: "Seattle, WA 98103",
};
